exports.components = {
  "component---src-pages-carbide-js": () => import("./../../../src/pages/carbide.js" /* webpackChunkName: "component---src-pages-carbide-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-machines-js": () => import("./../../../src/pages/machines.js" /* webpackChunkName: "component---src-pages-machines-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-punches-js": () => import("./../../../src/pages/punches.js" /* webpackChunkName: "component---src-pages-punches-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */),
  "component---src-pages-segment-dies-js": () => import("./../../../src/pages/segment-dies.js" /* webpackChunkName: "component---src-pages-segment-dies-js" */),
  "component---src-pages-sjv-tools-js": () => import("./../../../src/pages/sjv-tools.js" /* webpackChunkName: "component---src-pages-sjv-tools-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thread-js": () => import("./../../../src/pages/thread.js" /* webpackChunkName: "component---src-pages-thread-js" */)
}

